<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <BlueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>


  </section>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { Role } from "@/router/role.js";
import BlueHeader from "../../../../components/BlueHeader.vue";

export default {
  name: 'PharmaceuticalServices',
  components: { BlueHeader },
  data: () => ({

    itemsHeader: [
      {
        name: 'Roles',
        link: { name: 'modules.settings.pharmaceutilcalService.roles' },
        rol: Role.Configuraciones_ServiciosFarmaceuticos_Roles,
      },
      {
        name: 'Medios',
        link: { name: 'modules.settings.pharmaceutilcalService.medios' },
        rol: Role.Configuraciones_ServiciosFarmaceuticos_Medios,
      },
      {
        name: 'Motivos intraHospitalario',
        link: { name: 'modules.settings.pharmaceutilcalService.motivosIntraHospitalario' },
        rol: Role.Configuraciones_ServiciosFarmaceuticos_MotivosIntrahospitalario,
      },
      {
        name: 'Homologación',
        link: { name: 'modules.settings.pharmaceutilcalService.homologacion' },
        rol: Role.Configuraciones_ServiciosFarmaceuticos_Homologacion,
      },
      {
        name: 'Afiliados',
        link: { name: 'modules.settings.pharmaceutilcalService.afiliados' },
        rol: Role.Configuraciones_ServiciosFarmaceuticos_Afiliados,
      },
      {
        name: 'Afiliado Ruta',
        link: { name: 'modules.settings.pharmaceutilcalService.afiliadoRuta' },
        rol: Role.Configuraciones_ServiciosFarmaceuticos_AfiliadoRuta,
      },
      {
        name: 'Restricciones',
        link: { name: 'modules.settings.pharmaceutilcalService.restricciones' },
        rol: Role.Configuraciones_ServiciosFarmaceuticos_Restricciones,
      },
      {
        name: 'Parametrización correos',
        link: { name: 'modules.settings.pharmaceutilcalService.parametrizacionCorreos' },
        rol: Role.Configuraciones_ServiciosFarmaceuticos_ParametrizacionCorreos,
      },
      {
        name: 'Notificaciones',
        link: { name: 'modules.settings.pharmaceutilcalService.notificaciones' },
        rol: Role.Configuraciones_ServiciosFarmaceuticos_Notificaciones,
      },
    ]
  }),

}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de styles                                                      ###### -->
<!-- #################################################################################### -->
<style scoped>
.subHeader-content {
  width: 100%;
}
</style>